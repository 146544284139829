import React from "react"
import { navigate } from "gatsby"
import ToppingView from "../ui/ToppingView"

const to = "/order-name";
const onNext = () => navigate(to);

const Page = ({ location }) => (
  <ToppingView toppingType="wet" title="Choose any drizzles." allowExtra to={to} onNext={onNext} />
);
export default Page;
